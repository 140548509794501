input[type='text'] {
    width: 300px;
}

input[type='password'] {
    width: 300px;
}

#appinfo dd {
    margin-left: 1em;
}
