@media print {
    img.notfound {
        display: none !important;
    }
}

img.notfound {
    max-width: 20%;
    max-height: 20%;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-touch-callout: none;
    -moz-user-select: none;
    user-select: none;
}
