div.box_title {
    margin: 0em;
    display: inline-block;
    position: relative;
    top: 3px;
    padding: 0 0.5em;
    height: 2.5em;
    line-height: 2.5em;
    font-size: 15px;
    color: #ffffff;
    background: #ffc0c0;
    font-weight: bold;
    text-align: center;
    border-radius: 5px 5px 0 0;
}

div.box_content {
    background: rgba(255, 255, 255, 0);
    border: solid 3px #ffc0c0;
    border-radius: 0 5px 5px;
    padding: 20px;
    font-size: 100%;
}
