@import url('//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/css/font-awesome.min.css');
.menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: #fff4f5;
}
.menu li a {
    display: block;
    height: 4em;
    width: 4em;
    text-indent: -400em;
    line-height: 4em;
    text-align: center;
    color: #ff5c62;
    background: #fff4f5;
    position: relative;
    border-bottom: 1px solid #ffe2e3;
    transition: background 0.3s ease-in-out;
}
.menu li a:before {
    font-family: FontAwesome;
    speak: none;
    text-indent: 0em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.12em;
}
.menu li a.search:before {
    content: '\f002';
}
.menu li a.archive:before {
    content: '\f187';
}
.menu li a.pencil:before {
    content: '\f040';
}
.menu li a.contact:before {
    content: '\f003';
}
.menu li a.about:before {
    content: '\f007';
}
.menu li a.home:before {
    content: '\f015';
}
.menu li a.myapp:before {
    content: '\f044';
}
.menu li a.linkedapp:before {
    content: '\f0ac';
}
.menu li a.personal:before {
    content: '\f15c';
}
.menu li a.signin:before {
    content: '\f090';
}
.menu li a.signout:before {
    content: '\f08b';
}
.menu li a.signup:before {
    content: '\f093';
}

.menu li a:hover {
    background: #ff5c62;
    color: #fff;
}
.menu li.current a {
    background: #ff5e5e;
    color: #fff;
}
.menu li a.active {
    background: #ff5e5e;
    color: #fff;
}
.menu li a.active:after {
    position: absolute;
    left: 4em;
    top: 0;
    content: '';
    border: 2em solid transparent;
    border-left-color: #ff5c62;
    border-width: 2em 0.8em;
}
.menu li {
    position: relative;
}
.menu li:after {
    content: attr(title);
    position: absolute;
    left: 4em;
    top: 0;
    width: 11.2em;
    height: 4em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    background: #ff5c62;
    padding: 1.6em;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}
.menu li:hover:after {
    visibility: visible;
    opacity: 1;
}
@media screen and (max-height: 34em) {
    .menu li {
        font-size: 70%;
    }
}
